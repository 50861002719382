@import url('https://fonts.googleapis.com/css2?family=Manjari:wght@100;400;700&display=swap');

body {
    margin: 0;
    border: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Manjari', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:'#F8F8F8';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar {
    width: 0.4em;
    height: 8px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #6565657a;
}